'use strict';

angular.module('tailor')
  .service('auth0Service', function() {
    const createClient = function () {
      return auth0.createAuth0Client({
        domain: 'https://sandbox-login.jellydevs.com',
        clientId: window.env.AUTH0_BA_CLIENT_ID,
        connection:"jellyvision-okta", // connections in application take precendence and WILL overlook what is specificed here if not checked 
        authorizationParams: {
          redirect_uri: window.location.origin + '/#!/auth0-test-login'
        },
        cacheLocation: 'localstorage'
      });
    };
    return { createClient };
  });
